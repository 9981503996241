<template>
  <v-app>
    <v-main>
        <v-app-bar elevation="0" class="pl-16" color="white">
            <Header/>
      </v-app-bar>
        <Menu />

      <v-slide-x-reverse-transition>
        <v-timeline
          dense
          class="pr-5 mt-10 overflow-hidden"
          :reverse="false"
          v-show="mostrar"
        >
            <v-timeline-item
              v-for="(n, index) in datos"
              :key="index"
            >
              <span class="mb-6 fecha-time-line">{{ index }}</span>
              <v-slide-x-reverse-transition :group="true">
              <v-card class="elevation-2 evento"
                v-for="(y, index2) in n"
                :key="index2"
              >
                <v-card-title class="text-h5">
                  {{ y.mstTitulo }}
                </v-card-title>
                <v-card-text>
                  {{ y.mstDesc }}
                </v-card-text>
                <v-img
                  v-if="y.mstImagen != null"
                  :src="`data:image/jpeg;base64,${y.mstImagen}`"
                  height="200px"
                ></v-img>
              </v-card>
              </v-slide-x-reverse-transition>
            </v-timeline-item> 
        </v-timeline>
      </v-slide-x-reverse-transition>
    </v-main>   
  </v-app>
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'
  import Header from '../components/TimeLine/TLHeader.vue'
  import { TimelineApiService } from '../api/TimelineApiService'
  const timelineApi = new TimelineApiService() 

  export default Vue.extend({
    name: 'TimeLine',
    data () {
      return {
        datos: [],
        mostrar: false
      }
    },
    components: {
      Menu,
      Header,
    },
    created() {
      this.$root.$emit('verificarCertificado', location.href)
      window.addEventListener('scroll', this.handleScroll)
      this.getTimeLine()
      
    },
    mounted() {
      this.mostrar = true
      this.handleScroll()
    },
    methods: {
      getTimeLine () {
        timelineApi.getAllTimeline()
          .then(data => {
              let contador = 0  
              const groupBy = key => array =>
              array.reduce((objectsByKeyValue, obj) => {
                  const value = obj[key]
                  obj.x = contador++
                  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
                  return objectsByKeyValue
              }, {})

              const groupByFecha = groupBy('mstFecha')
              this.datos = groupByFecha(data)
              this.handleScroll()
          })
      },
      handleScroll() {
        let elementoslista = document.querySelectorAll('.evento')
        for (var i = 0; i < elementoslista.length; i++) {
            if (this.comprobarventana(elementoslista[i])) {
                elementoslista[i].classList.add("visible");
                elementoslista[i].classList.remove('novisible');
            }
            else
            {
                elementoslista[i].classList.remove('visible');
                elementoslista[i].classList.add("novisible");
            }  
        }
      },
      comprobarventana(elemento){
          let dato = elemento.getBoundingClientRect();
          return (
              dato.top >= -300 &&
              dato.bottom <= window.innerHeight + 300
          );
      },
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
    }
  })
</script>
<style scoped>
.visible {
  visibility: visible;
}
.novisible {
  visibility: hidden;
}
.evento::after {
  transition: background .5s ease-in-out;
}

.evento {
  transition: all .3s ease-in-out;
}
.evento.novisible {
  transform: translate3d(40px, 0, 0);
}
.evento.visible {
  transform: none;
  top: -35px;
}  
.fecha-time-line {
  left: -100px;
    position: relative;
    display: inline-block;
    background: white;
    padding: 15px;
    font-size: 1.1rem;
    font-weight: 600;
    /* transform: rotate(270deg); */
}
</style>